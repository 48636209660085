import React from "react";
import { graphql } from 'gatsby';
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Article from "../components/article";

const TopicListTemplate = ({ data, pageContext }) => {

const TopicArticleItems = data.allContentfulArticle.edges;

const { currentPage, topicArticleLength, numPagesTopicArticles, topic, allTopicsArray, topicSubHeadings, topicSlug, allTopicsSlugs, skip, limit } = pageContext;
const isFirst = currentPage === 1;
const isLast = currentPage === numPagesTopicArticles;
const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
const nextPage = (currentPage + 1).toString();

  return (
    <Layout
      pageHeading={topic}
      subHeading={topicSubHeadings}
    >
      <SEO
        title={
          topic === `Books`
            ? `UX Book Reviews and Chapters | SpotlightUX`
            : `${topic} Articles | SpotlightUX`
          }
        description={topicSubHeadings}
        body="article topics" />
      <div className="layout-wrapper">
        <div className="flex">
          <main>
            <div className="my-container">
              <h2>
              {currentPage ===  1
                ? `${topicArticleLength} Articles`
                : skip + limit < topicArticleLength ? `Page ${currentPage} (${skip + 1} \u2013 ${skip + limit} of ${topicArticleLength} Articles)` : `Page ${currentPage} (${skip + 1} \u2013 ${topicArticleLength} of ${topicArticleLength} Articles)`
              }</h2>
              <div className="list-wrapper">
                {TopicArticleItems.map(({ node }) =>
                    <Article
                      key={node.id}
                      date={node.date}
                      description={node.description.description}
                      source={node.source}
                      title={node.title}
                      articleLink={node.link}
                      author={node.author}
                    />
                )}
              </div>
              <div className="pagination">
                <span>
                {!isFirst && (
                  <Link to={currentPage === 2 ? `/topic/${topicSlug}/` : `/topic/${topicSlug}/page/${prevPage}/`} className="button">Previous Page</Link>
                )}
                </span>
                <span>
                {!isLast && (
                  <Link to={`/topic/${topicSlug}/page/${nextPage}/`} className="button">Next Page</Link>
                )}
                </span>
              </div>
            </div>
          </main>
          <aside className="topics">
            <h2>Topics</h2>
            <ul>
            {allTopicsArray.map((topic, i) => {
              return <li key={i}><Link to={`/topic/${allTopicsSlugs[i]}/`}>{topic}</Link></li>
            })}
            </ul>
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query TopicsQuery($topic: String!, $skip: Int!, $limit: Int!) {
  allContentfulArticle(
    filter: {category: {elemMatch: {category: {eq: $topic }}}}
    sort: {fields: date, order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        author
        date(formatString: "MMMM D, YYYY")
        description {
          description
        }
        id
        link
        source
        title
      }
    }
  }
}
`

export default TopicListTemplate;
