import React, { Component } from 'react';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
        fNameValue: '',
        emailValue: '',
    };
  }

  render() {
    return (
      <div className="signup-background">
        <div className="layout-wrapper">
          <div className="newsletter">
            <h2>Subscribe to get the latest UX content</h2>
            <p>Receive emails every 1-2 weeks on all things UX, including updates on new books and upcoming conferences.</p>
            <div id="mc_embed_signup">
              <form action="https://spotlightux.us20.list-manage.com/subscribe/post?u=9487e44ac7fabae4bb5f6951f&amp;id=9f300014c4" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                <div id="mc_embed_signup_scroll" className="field-group">
                  <div className="input-container">
                  	<input
                      type="text"
                      value={this.state.fNameValue}
                      onChange={(e)=>{this.setState({fNameValue: e.target.value});}}
                      name="FNAME"
                      className="required"
                      id="mce-FNAME"
                      placeholder="Your First Name"
                      aria-label="First name"
                      required
                    />
                  </div>
                  <div className="input-container">
                  	<input
                      type="email"
                      value={this.state.emailValue}
                      onChange={(e)=>{this.setState({emailValue: e.target.value});}}
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      placeholder="Your Email Address"
                      aria-label="Email address"
                      required
                    />
                  </div>
                    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                  <div style={{position: `absolute`, left: `-5000px`}} aria-hidden="true">
                    <input
                      type="text"
                      name="b_9487e44ac7fabae4bb5f6951f_9f300014c4"
                      tabIndex="-1"
                      defaultValue=''
                      aria-label="Don't fill out"/>
                  </div>
                  <div className="input-container">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                      aria-label="Subscribe"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Signup
