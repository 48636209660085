import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

const Header = ({ siteTitle, siteDescription, pageHeading, subHeading }) => (
  <div className="hero-background-color">
    <div className="top-wrapper">
      <header>
        <span className="logo">
          <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="146.04" height="146.04" viewBox="0 0 146.04 146.04">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <polygon points="73.02 0 0 73.02 34.9 107.92 72.61 5.33 73.01 146.03 73.02 146.04 146.04 73.02 73.02 0" style={{fill: `#fff`}}/>
                </g>
              </g>
            </svg>
            <span className="name">SpotlightUX</span>
          </Link>
        </span>
        <Link to="/articles/" className="home-button">All Articles
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.828" viewBox="0 0 16 16.828">
            <g id="arrow-right" transform="translate(1 1.414)">
              <line id="Line_1" data-name="Line 1" x2="14" transform="translate(0 7)" fill="none" stroke="#0a47ed" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
              <path id="Path_13" data-name="Path 13" d="M12,5l7,7-7,7" transform="translate(-5 -5)" fill="none" stroke="#0a47ed" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </g>
          </svg>
        </Link>
      </header>
      <section className="hero">
        <h1>{pageHeading}</h1>
        <p className="subhead">{subHeading}</p>
      </section>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
