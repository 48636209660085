import React from "react"

const Article = ({ date, description, source, title, articleLink, author, id }) => {
  return (
    <article className="list-body" key={id}>
      <h3 className="list-title">
        <a target="_blank" rel="noopener noreferrer" href={articleLink} className="list-link">{title}</a>
      </h3>
      <span className="text-style">Written by {author} in {source}</span>
      <p className="list-text"><span className="list-date">{date} &mdash; </span>{description}</p>
      <a target="_blank" rel="noopener noreferrer" href={articleLink} className="external">Read this story
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
          <polyline points="15 3 21 3 21 9"></polyline>
          <line x1="10" y1="14" x2="21" y2="3"></line>
        </svg>
      </a>
    </article>
  )
}

export default Article
