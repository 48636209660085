import React from "react"

const Footer = () => {
  return(
    <div className="footer-background">
      <div className="footer-wrapper">
        <footer>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 146.04 146.04">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <polygon points="73.02 0 0 73.02 34.9 107.92 72.61 5.33 73.01 146.03 73.02 146.04 146.04 73.02 73.02 0" style={{fill: `#0a47ed`}}/>
              </g>
            </g>
          </svg>
          <p>SpotlightUX 2021. Built and curated by <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/anup-sheth-253aa192/">Anup Sheth</a>.</p>
          <p>Powered by <a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.com/">Gatsby</a> and <a target="_blank" rel="noopener noreferrer" href="https://www.contentful.com/">Contentful</a>.</p>
        </footer>
      </div>
    </div>
  )
}

export default Footer
